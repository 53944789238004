import { Row, Col, Button, Input, Form, notification, message, Switch } from "antd";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import config from "../../utils/config.js";

import "./Settings.css";
import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";
import Dragger from "antd/es/upload/Dragger.js";
import ReactQuill from "react-quill";
import { InboxOutlined } from "@ant-design/icons";
import upload from "../../utils/upload.js";

function SettingsTogetherAZOne({ data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState(null);
  const [form] = Form.useForm();

  const uploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    action: config.server_ip + endpoints.settings.upload,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      let compressedFile = await upload.compress(file);
      setFileList([compressedFile]);
      getBase64(compressedFile, (url) => {
        setFilePreview(url);
      });
      return false;
    },
    fileList,
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          ["link"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript

          ["clean"], // remove formatting button
        ],
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    []
  );

  useEffect(() => {
    handleGetData();
  }, [data]);

  function handleGetData() {
    axios
      .get(endpoints.settings.readTogetherAzOne)
      .then((res) => {
        if (res.data.length > 0) {
          let togetherAzOne = res.data.filter((item) => item.name === "togetherazone")[0];
          setFileList([{ id: "not_new", name: togetherAzOne.image }]);
          console.log(`${config.server_ip}media/${togetherAzOne.image}`);
          setFilePreview(`${config.server_ip}media/${togetherAzOne.image}`);
          togetherAzOne.image = [{ id: "not_new", name: togetherAzOne.image }];

          form.setFieldsValue(togetherAzOne);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    const formData = new FormData();

    fileList.forEach(async (file) => {
      console.log(file);
      if (file.id !== "not_new") {
        formData.append("file", file);
      } else {
        values.image = file.name;
      }
    });

    formData.append("data", JSON.stringify(values));
    axios
      .post(endpoints.settings.updateTogetherAzOne, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resData) => {
        setIsButtonLoading(false);
        notification.success({
          message: "Success!",
          description: "Definições foram editadas com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function normFile(e) {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  return (
    <div className="dashboard_page">
      <Row className="dashboard_content_row">
        <Col span={24}>
          <Form form={form} id="add-commission_type-form" layout="vertical" onFinish={handleSubmit} onFinishFailed={handleWarningMissingFields}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="is_active" label="Ativo" valuePropName="checked">
              <Switch size="large" />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}>
              {({ getFieldValue }) => {
                console.log(getFieldValue("image"));
                return (
                  <Form.Item name="image" label="Imagem">
                    <Dragger {...uploadProps}>
                      {!filePreview ? (
                        <div>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined
                              style={{
                                color: "rgb(103 103 103)",
                              }}
                            />
                          </p>
                          <p className="f-18 mb-0">
                            <b>Upload da imagem</b>
                          </p>
                          <p className="mt-0">Faça Drag and drop aqui</p>
                        </div>
                      ) : (
                        <div
                          className={`preview_image`}
                          style={{
                            backgroundImage: `url(${filePreview ? filePreview : null})`,
                          }}
                        ></div>
                      )}
                    </Dragger>
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item label="Text" name="text">
              <ReactQuill key="description" theme="snow" modules={modules} />
            </Form.Item>
            <Button type="primary" size="large" onClick={form.submit} loading={isButtonLoading}>
              Save
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default SettingsTogetherAZOne;
