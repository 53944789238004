const endpoints = {
  auth: {
    login: "auth/login",
    register: "auth/register",
    verifyUser: "auth/verifyUser",
    verifyToken: "auth/verifyToken",
    renewToken: "auth/renewToken",
    resetPasswordEmail: "auth/resetPasswordEmail",
    resetPassword: "auth/resetPassword",
  },
  settings: {
    read: "app/settings/read",
    readTogetherAzOne: "app/settings/readTogetherAzOne",
    update: "app/settings/update",
    updateTogetherAzOne: "app/settings/updateTogetherAzOne",
    upload: "app/settings/upload",
  },
  program: {
    read: "app/program/read",
    readyById: "app/program/readById",
    create: "app/program/create",
    update: "app/program/update",
    delete: "app/program/delete",
    rate: "app/program/rate",
  },
  challenges: {
    read: "app/challenges/read",
    readyById: "app/challenges/readById",
    create: "app/challenges/create",
    update: "app/challenges/update",
    active: "app/challenges/active",
    end: "app/challenges/end",
    delete: "app/challenges/delete",
  },
  location: {
    read: "app/location/read",
    readyById: "app/location/readById",
    create: "app/location/create",
    update: "app/location/update",
    delete: "app/location/delete",
  },
  notification: {
    read: "app/notification/read",
    readById: "app/notification/readyById",
    create: "app/notification/create",
    update: "app/notification/update",
    delete: "app/notification/delete",
  },
  user: {
    create: "app/user/create",
    read: "app/user/read",
    readById: "app/user/readById",
    update: "app/user/update",
    updateDeviceToken: "app/user/updateDeviceToken",
    delete: "app/user/delete",
    readFileImport: "app/user/readFileImport",
    import: "app/user/import",
    changePhoto: "app/user/changePhoto",
  },
  messages: {
    read: "app/message/read",
    readUnred: "app/message/readUnread",
    readDetails: "app/message/readDetails",
    sendNotificationMessage: "app/message/sendNotificationMessage",
    create: "app/message/create",
    updateRead: "app/message/updateRead",
  },
  photo: {
    read: "app/photo/read",
    update: "app/photo/update",
    create: "app/photo/create",
    delete: "app/photo/delete",
    like: "app/photo/like",
    unlike: "app/photo/unlike",
  },
  device: {
    getDevice: "app/device/readByToken",
    getDevices: "app/device/read",
    create: "app/device/create",
  },
  voting: {
    create: "app/voting/create",
    read: "app/voting/read",
    update: "app/voting/update",
    delete: "app/voting/delete",
  },
  teams: {
    create: "app/teams/create",
    read: "app/teams/read",
    readByUser: "app/teams/readByUser",
    update: "app/teams/update",
    delete: "app/teams/delete",
  },
  evaluation: {
    create: "app/evaluation/create",
    createAnswer: "app/evaluation/createAnswer",
    read: "app/evaluation/read",
    readByUser: "app/evaluation/readByUser",
    update: "app/evaluation/update",
    delete: "app/evaluation/delete",
  },
  pco: {
    create: "app/pco/create",
    read: "app/pco/read",
    update: "app/pco/update",
    delete: "app/pco/delete",
  },
  transfer: {
    create: "app/transfer/create",
    read: "app/transfer/read",
    update: "app/transfer/update",
    delete: "app/transfer/delete",
  },
  faqs: {
    create: "app/faqs/create",
    read: "app/faqs/read",
    update: "app/faqs/update",
    delete: "app/faqs/delete",
  },
};

export default endpoints;
