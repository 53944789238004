import { Row, Col, Button, Divider, Avatar, Tooltip, Pagination, Spin, Form, Input, Empty, notification, Checkbox, Switch } from "antd";
import { EditOutlined, DeleteOutlined, UserOutlined, InboxOutlined, FilterOutlined, ReloadOutlined, PlayCircleOutlined, StopOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

import Create from "../../components/Challenges/Create.js";
import Update from "../../components/Challenges/Update.js";
import Delete from "../../components/Challenges/Delete.js";

import "./Challenges.css";
import "react-quill/dist/quill.snow.css";
import Search from "antd/es/input/Search";
import endpoints from "../../utils/endpoints.js";
import config from "../../utils/config.js";

export default function Challenges() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  const [form] = Form.useForm();
  const [formActive] = Form.useForm();

  useEffect(() => {
    handleGetData();
    handleGetSettings();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.challenges.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleGetSettings() {
    setIsLoading(true);
    axios
      .get(endpoints.settings.read)
      .then((res) => {
        const auxChallenges = res.data[0].filter((item) => item.name === "challenges")[0];
        formActive.setFieldsValue(JSON.parse(auxChallenges.json));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleStartChallenge(item) {
    axios
      .post(endpoints.challenges.active, {
        data: { id: item.id, status: "active", started_at: dayjs().format("YYYY-MM-DD HH:mm:ss"), title: item.title },
      })
      .then((res) => {
        handleGetData();
        notification.success({
          message: "Challenge is now active!",
          description: "Challenge is active and a notification was sent!",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleEndChallenge(item) {
    axios
      .post(endpoints.challenges.end, {
        data: { id: item.id, status: "ended", ended_at: dayjs().format("YYYY-MM-DD HH:mm:ss") },
      })
      .then((res) => {
        console.log(res);
        handleGetData();
        notification.success({
          message: "Challenge is now closed!",
          description: "Challenge is closed!",
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);

    let auxArray = [];

    for (let i = 0; i < data.length; i++) {
      let arrayData = data[i];

      let aux = {
        key: i,
        title: arrayData.title,
        description: arrayData.description ? <div dangerouslySetInnerHTML={{ __html: arrayData.description }} /> : null,
        local: arrayData.local,
        qrcodes: arrayData.qrcodes ? JSON.parse(arrayData.qrcodes) : null,
        location_name: arrayData.location_name,
        status: arrayData.status,
        actions: (
          <div className="actions-buttons-div">
            {arrayData.status === "pending" && <PlayCircleOutlined className="edit-action" onClick={() => handleStartChallenge(data[i])} />}
            {arrayData.status === "active" && <StopOutlined className="edit-action" onClick={() => handleEndChallenge(data[i])} />}
            {arrayData.status !== "ended" && <EditOutlined className="edit-action" onClick={() => handleOpenUpdate(data[i])} />}
            <DeleteOutlined className="f-24 edit-action" onClick={() => handleOpenDelete(data[i])} />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(_, allValues) {
    let value = allValues.search;
    const newData = data.filter((item) => item.title.toLowerCase().includes(value.toLowerCase()));

    handlePrepareData(newData);
  }

  function handleActiveChallenges(_, allValues) {
    axios
      .post(endpoints.settings.update, {
        data: { values: allValues, name: "challenges" },
      })
      .then((resData) => {
        notification.success({
          message: "Success!",
          description: "Challenges are now " + (allValues.active ? "active" : "inactive") + "!",
        });
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Challenges</h3>
        </Col>
        <Col span={12}>
          <Row className="d-flex jc-end ai-center">
            <Form layout="horizontal" form={formActive} onValuesChange={handleActiveChallenges} className="mr-10">
              <Form.Item label="Active" name="is_active" className="m-0" valuePropName="checked">
                <Switch size="large" />
              </Form.Item>
            </Form>
            <Form form={form} onValuesChange={handleSearch} className="mr-10">
              <Form.Item name="search" className="m-0">
                <Input style={{ borderRadius: 0 }} size="large" placeholder="Search..." />
              </Form.Item>
            </Form>
            <Button className="mr-10" size="large" onClick={handleGetData}>
              <ReloadOutlined />
            </Button>
            <Button type="primary" size="large" onClick={handleOpenCreate}>
              Create
            </Button>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="challenge_item">
                        <Col span={24}>
                          <Row gutter={[24]}>
                            <Col span={8} className="d-flex jc-start ai-center">
                              <div className="d-flex flex-column">
                                <span className="f-12">Title</span>
                                <span className="f-18">{item.title}</span>
                              </div>
                            </Col>
                            <Col span={10} className="d-flex jc-start ai-center">
                              {item.description && (
                                <div className="d-flex flex-column">
                                  <span className="f-12">Description</span>
                                  <span className="f-18">{item.description}</span>
                                </div>
                              )}
                            </Col>
                            <Col span={2} className="d-flex jc-end ai-center">
                              <p>{item.status}</p>
                            </Col>
                            <Col span={4} className="d-flex jc-end ai-center">
                              {item.actions}
                            </Col>
                          </Row>
                          {item.qrcodes ? (
                            <>
                              <Divider />
                              <Row className="info" gutter={[24]}>
                                {item.qrcodes.map((qr, i) => (
                                  <Col span={2} className="d-flex flex-column jc-start ai-center">
                                    <img src={`${config.server_ip}media/${qr.name}.jpg`} className="w-100" />
                                    <p>{qr.points} points</p>
                                  </Col>
                                ))}
                              </Row>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={data.length} />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <Empty style={{ color: "#c1c1c1" }} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
