import Compressor from "compressorjs";

const upload = {
  compress: (file) => {
    return new Promise((resolve, reject) => {
      if (file.type.includes("image/svg")) {
        resolve(file);
      } else {
        console.log(file);
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            console.log(result);
            resolve(result);
          },
          error(err) {
            console.log(err);
            reject(err);
          },
        });
      }
    });
  },
};

export default upload;
