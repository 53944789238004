import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Drawer,
  message,
  Input,
  Row,
  Col,
  Select,
  Switch,
} from 'antd';
import axios from 'axios';
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";

import endpoints from "../../utils/endpoints";
import "react-quill/dist/quill.snow.css"
import config from "../../utils/config";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

function Create({ data, open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState('');

  const [form] = useForm();

  const uploadProps = {
    name: 'file',
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview('');
    },
    beforeUpload: (file) => {
      handlePreview(file, 'image');
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: []
  };

  useEffect(() => {
    if (open) {
      if (typeof data.img === 'string') {
        setFilePreview(data.img ? data.img : '')
        data.img = [{ id: 'not_new', name: data.img }]
      } else {
        setFilePreview(data.img ? data.img[0].name : '')
      }
      form.setFieldsValue(data);
    }
  }, [open]);

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        if (file.uid !== 'not_new') {
          formData.append('file', file);
        } else {
          values.img = file.name
        }
      });
    } else {
      values.img = null;
    }

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.location.update}`, {
      method: "POST",
      body: formData
    }).then(res => res.json())
      .then((resData) => {
      setTimeout(() => {
        setIsButtonLoading(false);
        handleClose();
        form.resetFields();
      }, 1000);
    }).catch(error => {
      setIsButtonLoading(false);
      console.error(error);
    });
  }

  function normFile(e) {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview)
  }


  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Edit location"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>Cancel</Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Update
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          name="id"
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="img"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger {...uploadProps} className={`user-img-dragger dragger ${filePreview ? 'hasPreview' : ''}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <div
              className={`preview_file ${filePreview ? 'hasPreview' : ''}`}
              style={{ backgroundImage: `url(${(filePreview && filePreview.includes('base64')) ? filePreview : `${config.server_ip}media/${filePreview}`})` }}
            >
            </div>
          </Dragger>
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}
        >
          <Input placeholder="Name..." size="large" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <ReactQuill
            placeholder="Description"
            className="title_react_quill"
            theme="snow"
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
        >
          <Select
            placeholder="Type..."
            options={[
              { value: 'restaurant', label: 'Restaurant' },
              { value: 'hotel', label: 'Hotel' }
            ]}
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}
        >
          <Input placeholder="City..." size="large" />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
        >
          <Input placeholder="Link..." size="large" />
        </Form.Item>

        <Form.Item
          label="Active"
          name="is_active"
          valuePropName="checked"
        >
          <Switch size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Create;