import { Breadcrumb, Layout, Menu, theme, Row, Col, Button } from "antd";
import { useState, useEffect } from "react";
import { Outlet, RouterProvider, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  FileOutlined,
  FileTextOutlined,
  LogoutOutlined,
  NotificationOutlined,
  MailOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  CarOutlined,
  FileFilled,
  CarFilled,
  QuestionCircleFilled,
  NotificationFilled,
  InfoCircleFilled,
  SettingFilled,
} from "@ant-design/icons";

import { ReactComponent as ProgramIcon } from "../../assets/images/menu/Program.svg";
import { ReactComponent as ChallengeIcon } from "../../assets/images/menu/Challenge.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/menu/Profile.svg";
import { ReactComponent as DiscoverIcon } from "../../assets/images/menu/Discover.svg";
import { ReactComponent as TeamIcon } from "../../assets/images/menu/Teams.svg";
import { ReactComponent as FavouriteIcon } from "../../assets/images/menu/Favourite.svg";
import { ReactComponent as FaqsIcon } from "../../assets/images/menu/Faqs.svg";
import { ReactComponent as AboutIcon } from "../../assets/images/menu/About.svg";

import logo from "../../assets/images/logo-together-white.svg";

import axios from "axios";
import config from "../../utils/config";
import Loading from "../../components/Loading/Loading";
import "./Main.css";
import endpoints from "../../utils/endpoints";
import { FiAirplay, FiMapPin, FiThumbsUp } from "react-icons/fi";

const { Content, Sider, Header } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem(
    "PROGRAM",
    "/app/program",
    <div className="icon-menu">
      <ProgramIcon />
    </div>
  ),
  getItem(
    "CHALLENGES",
    "/app/challenges",
    <div className="icon-menu">
      <ChallengeIcon />
    </div>
  ),
  getItem(
    "USERS",
    "/app/users",
    <div className="icon-menu">
      <UsersIcon />
    </div>
  ),
  getItem(
    "LOCATIONS",
    "/app/locations",
    <div className="icon-menu">
      <DiscoverIcon />
    </div>
  ),
  // getItem(
  //   "VOTING",
  //   "/voting",
  //   <div className="icon-menu">
  //     <FavouriteIcon />
  //   </div>
  // ),
  getItem(
    "TEAMS",
    "/app/teams",
    <div className="icon-menu">
      <TeamIcon />
    </div>
  ),
  getItem(
    "EVALUATION",
    "/app/evaluation",
    <div className="icon-menu">
      <FileFilled />
    </div>
  ),
  getItem(
    "TRANSFERS",
    "/app/transfers",
    <div className="icon-menu">
      <CarFilled />
    </div>
  ),
  getItem(
    "FAQS",
    "/app/faqs",
    <div className="icon-menu">
      <QuestionCircleFilled />
    </div>
  ),
  getItem(
    "NOTIFICATIONS",
    "/app/notifications",
    <div className="icon-menu">
      <NotificationFilled />
    </div>
  ),
  getItem(
    "OFFICIAL AGENCY",
    "/app/pco",
    <div className="icon-menu">
      <InfoCircleFilled />
    </div>
  ),
  getItem(
    "SETTINGS",
    "/app/settings",
    <div className="icon-menu">
      <SettingFilled />
    </div>
  ),
];

const MainDashboard = ({ handleLogout }) => {
  const location = useLocation();
  const pathname = location.pathname.split("/");

  const [user, setuser] = useState({});
  const [current, setCurrent] = useState(pathname[pathname.length - 1] === "" ? "/" : `/${pathname[pathname.length - 1]}`);

  console.log(pathname);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    if (tokenData) {
      axios
        .post(endpoints.auth.verifyToken, {
          data: tokenData,
        })
        .then((res) => {
          console.log(res);
          if (res.data.token_valid) {
            setuser(res.data.token_decoded);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            navigate("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    setCurrent(pathname[pathname.length - 1] === "" ? "/" : `/${pathname[pathname.length - 1]}`);
  }, [location]);

  function handleClickMenu(e) {
    if (e.key === "logout") {
      handleLogout();
    } else {
      navigate(e.key);
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Layout className="admin_layout">
          <Sider width={250}>
            <div className="logo" onClick={() => navigate("/")}>
              <img src={logo} />
            </div>
            <Menu theme="dark" defaultSelectedKeys={[current]} selectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
          </Sider>
          <Layout className="site-layout">
            <Header style={{ padding: 0 }}>
              <Row className="h-100">
                <Col span={12}></Col>
                <Col span={12} className="d-flex jc-end ai-center">
                  <div className="user_header">
                    <UserOutlined />
                    {user.name}
                  </div>
                  <div className="logout_header">
                    <Button type="text" icon={<LogoutOutlined />} onClick={handleLogout}>
                      {" "}
                      Terminar Sessão
                    </Button>
                  </div>
                </Col>
              </Row>
            </Header>
            <Content className="admin_layout_content">
              <Outlet context={user} />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};
export default MainDashboard;
