import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Drawer,
  message,
  Input,
  Row,
  Col,
  Select,
  Switch,
} from 'antd';
import axios from 'axios';
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";

import endpoints from "../../utils/endpoints";
import "react-quill/dist/quill.snow.css"
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import config from "../../utils/config";

function Create({ data, open, close }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [form] = useForm();

  const uploadProps = {
    name: 'file',
    accept: '.pdf',
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: []
  };

  useEffect(() => {
    if (open) {
      if (typeof data.file === 'string') {
        data.file = [{ id: 'not_new', name: data.file }]
      }

      form.setFieldsValue(data);
    }
  }, [open]);

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        if (file.uid !== 'not_new') {
          formData.append('file', file);
        } else {
          values.file = file.name
        }
      });
    } else {
      values.file = null;
    }

    formData.append('data', JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.transfer.update}`, {
      method: "POST",
      body: formData
    }).then(res => res.json())
      .then((resData) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
        }, 1000);
      }).catch(error => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  function normFile(e) {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Edit transfer"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>Cancel</Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Update
        </Button>
      ]}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item
          name="id"
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="file"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Dragger {...uploadProps} className={`user-img-dragger dragger`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </Dragger>
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: 'This field is required'
            }
          ]}
        >
          <Input placeholder="Name..." size="large" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
        >
          <Input placeholder="Description..." size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Create;